import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

class LoSentimosFico extends Component {
    constructor(props) {
        super(props)
    }

    // function LoSentimosAfi({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(LoSentimosFico, 'VDPNSolicitudExiste')
LoSentimosFico.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
LoSentimosFico.defaultProps = {
    companyName: "Afi",
    dataInfo: {
        "textHeader": "Ya cuentas con una solicitud en proceso.",
        "textBox": "Consulta el estatus llamando a Atención a Clientes al 800-891-2778 de 8am a 8pm de  Lunes a Sábado.",
        "textBoxTwo": "",
        "textBoxThree": ""
    }
}
// export default LoSentimosAfi

